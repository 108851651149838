import * as React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from '~/utils/misc'

const flexBoxVariants = cva('flex', {
  variants: {
    direction: {
      row: 'flex-row',
      rowReverse: 'flex-row-reverse',
      column: 'flex-col',
      colReverse: 'flex-col-reverse',
    },
    items: {
      default: 'items-start',
      center: 'items-center',
      end: 'items-end',
      baseline: 'items-baseline',
      stretch: 'items-stretch',
    },
    justify: {
      default: 'justify-start',
      center: 'justify-center',
      end: 'justify-end',
      between: 'justify-between',
      around: 'justify-around',
      evenly: 'justify-evenly',
    },
    gap: {
      xs: 'gap-1',
      sm: 'gap-2',
      md: 'gap-3',
      lg: 'gap-4',
    },
    wrap: {
      default: 'flex-nowrap',
      wrap: 'flex-wrap',
      wrapReverse: 'flex-wrap-reverse',
    },
  },
  defaultVariants: {
    direction: 'row',
    items: 'default',
    justify: 'default',
    wrap: 'default',
  },
})

export interface FlexBoxProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof flexBoxVariants> {}

const FlexBox = React.forwardRef<HTMLDivElement, FlexBoxProps>(
  ({ className, direction, items, justify, gap, wrap, ...props }, ref) => {
    return (
      <div
        className={cn(
          flexBoxVariants({ direction, items, justify, gap, wrap, className }),
        )}
        ref={ref}
        {...props}
      />
    )
  },
)

export { FlexBox, flexBoxVariants }
